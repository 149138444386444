import React from "react";
import { Link } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";
import ArchivedUserTable from "./archivedUserTable";
import { ArchivedUsers } from './assets/archivedUsers'
import { useStateManager } from "../handlers/statemanager";
const Archived: React.FC = () => {
  const { state } = useStateManager()

  return (
    <div className="h-full w-full flex flex-col gap-4">
      <Link to="/organization">
        <p className="text-[#3A21C4] text-sm font-medium font-inter cursor-pointer leading-tight flex items-center gap-3">
          <CaretLeft size={20} /> Back to main list
        </p>
      </Link>
      <div className="w-full h-full bg-white rounded-lg pt-3 px-4 flex flex-col gap-2">
        {state.archivedUsers?.length > 0 ? (<><ArchivedUserTable /></>) : (<ArchivedUsers />)}

        {/* {state.archivedUsers.map((x: any) => {return (<li>{x.user_first_name}</li>)})} */}
      </div>
    </div>
  );
};

export default Archived;
