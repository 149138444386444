import React, { useState } from 'react';
import { Button } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { Trophy, Gear, TreeStructure } from '@phosphor-icons/react';

interface SidebarProps {
  // Define props if needed
}

const Sidebar: React.FC<SidebarProps> = () => {
  const [selectedButton, setSelectedButton] = useState<string>('organization'); // State to track the selected button
  const navigate = useNavigate();

  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName); // Update selected button state
    navigate(`/${buttonName}`);
  };


  return (
    <div className="sidebar bg-white flex flex-col items-start p-6 gap-4 lg:min-w-[20%]  ">
      <Button
        className={`flex h-12 px-4 items-center gap-3 self-stretch rounded-md ${selectedButton === 'productivity' ? 'bg-[#ECE8FF]' : 'bg-white'
          } text-base leading-6 font-medium font-inter text-[#3A21C4]`}
        onClick={() => handleButtonClick('productivity')}
        isDisabled
      >
        <Trophy size={24} className="shrink-0" />
        <span className="hidden sm:inline md:hidden">Productivity</span>
        <span className="hidden md:inline">Productivity</span>
      </Button>

      <Button
        className={`flex h-12 px-4 items-center gap-3 self-stretch rounded-md ${selectedButton === 'organization' ? 'bg-[#ECE8FF]' : 'bg-white'
          } text-base leading-6 font-medium font-inter text-[#3A21C4]`}
        onClick={() => handleButtonClick('organization')}
      >
        <TreeStructure size={24} className="shrink-0" />
        <span className="hidden sm:inline md:hidden">Organization</span>
        <span className="hidden md:inline">Organization</span>
      </Button>

      <Button
        className={`flex h-12 px-4 items-center gap-3 self-stretch rounded-md ${selectedButton === 'preferences' ? 'bg-[#ECE8FF]' : 'bg-white'
          } text-base leading-6 font-medium font-inter text-[#3A21C4]`}
        onClick={() => handleButtonClick('preferences')}
        isDisabled
      >
        <Gear size={24} className="shrink-0" />
        <span className="hidden sm:inline md:hidden">Preferences</span>
        <span className="hidden md:inline">Preferences</span>
      </Button>
    </div>
  );
};

export default Sidebar;
