import { BrowserRouter as Router } from 'react-router-dom';
import Header from './page_components/header';
import Sidebar from './page_components/sidbar';
import PageRouter from './page_components/pagerouter';

function App() {
  return (
    <Router basename='/admin_panel'>
      <div className="flex flex-col h-screen">
        <Header />
        <div className='flex h-screen'>
          <Sidebar />
          <PageRouter />
        </div>
      </div>
    </Router>
  );
}

export default App;
