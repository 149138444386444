import React, { FC } from "react";
import { Button, Input, Spinner } from "@nextui-org/react";
import { CaretRight, XCircle, WarningCircle } from "@phosphor-icons/react";
import OrgSvg from "./assets/organizationemptystate";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from "@nextui-org/react";
import UserTable from "./usertable";
import {
  createUserInvite,
  fetchUserDetails,
  updateOrgUser,
  fetchOrganizationUsers,
  sendUserInvite
} from "../handlers/api";
import { useStateManager } from "../handlers/statemanager";

interface OrganizationProps {
  // Define props here
}

interface FormData {
  email: string;
  team: string;
  firstName: string;
  lastName: string;
  entity: string;
  permissionLevel: number | string;
}

const Organization: FC<OrganizationProps> = () => {
  const { onOpenChange } = useDisclosure();
  const [canSubmit, setCanSubmit] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [, setEditEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [permissionLevel, setPermissionLevel] = useState(2);
  const { state } = useStateManager();
  const notify = () =>
    toast(
      <div className="flex w-80 items-start rounded-md bg-[#EDF7ED]  gap-2 border-red-500">
        <div className="flex h-full items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M15.2074 6.94825L9.16658 12.9891L5.87575 9.70742L4.58325 10.9999L9.16658 15.5833L16.4999 8.24992L15.2074 6.94825ZM10.9999 1.83325C5.93992 1.83325 1.83325 5.93992 1.83325 10.9999C1.83325 16.0599 5.93992 20.1666 10.9999 20.1666C16.0599 20.1666 20.1666 16.0599 20.1666 10.9999C20.1666 5.93992 16.0599 1.83325 10.9999 1.83325ZM10.9999 18.3333C6.94825 18.3333 3.66659 15.0516 3.66659 10.9999C3.66659 6.94825 6.94825 3.66659 10.9999 3.66659C15.0516 3.66659 18.3333 6.94825 18.3333 10.9999C18.3333 15.0516 15.0516 18.3333 10.9999 18.3333Z"
              fill="#2E7D32"
            />
          </svg>
        </div>
        <div className="flex flex-col ">
          <p className="text-green-900 font-inter text-base">
            Invite sent
          </p>
          <p className="text-green-900 font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
            User needs to accept the invite
          </p>
        </div>
      </div>
    );

  const errorNotify = () =>
    toast(
      <div className="flex w-80 items-start rounded-md bg-[#F31260] gap-2">
        <div className="flex h-full items-start">
          <WarningCircle size={22} className="text-white" />
        </div>
        <div className="flex flex-col ">
          <p className="text-white font-inter text-base">
            Invite Error
          </p>
          <p className="text-white font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
            There was an error submitting your request
          </p>
        </div>
      </div>
    );

  const editSuccesNotify = () =>
    toast(
      <div className="flex w-80 items-start rounded-md bg-[#EDF7ED]  gap-2 border-red-500">
        <div className="flex h-full items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M15.2074 6.94825L9.16658 12.9891L5.87575 9.70742L4.58325 10.9999L9.16658 15.5833L16.4999 8.24992L15.2074 6.94825ZM10.9999 1.83325C5.93992 1.83325 1.83325 5.93992 1.83325 10.9999C1.83325 16.0599 5.93992 20.1666 10.9999 20.1666C16.0599 20.1666 20.1666 16.0599 20.1666 10.9999C20.1666 5.93992 16.0599 1.83325 10.9999 1.83325ZM10.9999 18.3333C6.94825 18.3333 3.66659 15.0516 3.66659 10.9999C3.66659 6.94825 6.94825 3.66659 10.9999 3.66659C15.0516 3.66659 18.3333 6.94825 18.3333 10.9999C18.3333 15.0516 15.0516 18.3333 10.9999 18.3333Z"
              fill="#2E7D32"
            />
          </svg>
        </div>
        <div className="flex flex-col ">
          <p className="text-green-900 font-inter text-base">
            Selection saved
          </p>
          <p className="text-green-900 font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
            User profile has been updated
          </p>
        </div>
      </div>
    );


  const editErrorNotify = () =>
    toast(
      <div className="flex w-80 items-start rounded-md bg-[#F31260] gap-2">
        <div className="flex h-full items-start">
          <WarningCircle size={22} className="text-white" />
        </div>
        <div className="flex flex-col ">
          <p className="text-white font-inter text-base">
            Update Error
          </p>
          <p className="text-white font-roboto font-normal text-body2 text-sm leading-snug tracking-tighter">
            There was an error submitting your request
          </p>
        </div>
      </div>
    );




  const [formData, setFormData] = useState<FormData>({
    email: "",
    team: "",
    firstName: "",
    lastName: "",
    entity: "",
    permissionLevel: "",
  });
  const handleModelOpen = () => {
    state.setModelOpen(!state.modelOpen);

    setFormData({
      email: "",
      team: "",
      firstName: "",
      lastName: "",
      entity: "",
      permissionLevel: "",
    });
  };

  // useEffect(() => {

  // }, [third])

  const handleEditModelOpen = () => {
    state.setEditUserModelOpen(!state.editUserModelOpen);

    if (state.isSameClickedUser) {
      setIsLoading(false);
      state.setIsSameClickedUser(false);
      return;
    } else {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    const { team, email, permissionLevel } = formData;
    const isFormDataEmpty =
      team === "" || email === "" || permissionLevel === "";
    setCanSubmit(!isFormDataEmpty);
  }, [formData]);

  const [formErrors, setFormErrors] = useState<FormData>({
    email: "",
    team: "",
    firstName: "",
    lastName: "",
    entity: "",
    permissionLevel: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    let permissionLevelValue: number | string;

    if (value === "1") {
      permissionLevelValue = 1;
    } else if (value === "2") {
      permissionLevelValue = 2;
    } else {
      permissionLevelValue = value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: permissionLevelValue,
    }));

    //note not all of these being updated are permission level this was just a cleaner way for now will modify

    console.log("formData:", formData);
    console.log(`Updated ${name}: ${value} ${permissionLevelValue}`);
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (validateForm()) {
      submitForm();
    }
  };

  useEffect(() => {
    async function fetchUserDetailsForEdit(userId: any) {
      const data = await fetchUserDetails(state.refreshToken, userId);
      setEditEmail(data.email);

      const temp = {
        email: data.email,
        team: state.editUserDetails.team ? state.editUserDetails.team : "",
        firstName: state.editUserDetails.user_first_name,
        lastName: state.editUserDetails.user_last_name,
        entity: state.organization.name,
        permissionLevel: state.editUserDetails.role,
      };

      console.log("temp: ", temp);
      console.log(state.editUserDetails);

      setFormData({
        email: data.email,
        team: state.editUserDetails.team ? state.editUserDetails.team : "",
        firstName: state.editUserDetails.user_first_name,
        lastName: state.editUserDetails.user_last_name,
        entity: state.organization.name,
        permissionLevel: state.editUserDetails.role,
      });
      console.log("looking for permissions", state.editUserDetails);

      console.log("just set the starting edit formData:", formData);
      setIsLoading(false);
      setPermissionLevel(data.organizations[0].role);
      console.log(data.organizations[0].role, "data.org");
      console.log("userdetails data edit:", data);
    }

    if (state.editUserDetails) {
      fetchUserDetailsForEdit(state.editUserDetails.user);
    }
  }, [state.editUserDetails, state?.organization?.name, formData, state.refreshToken]);

  useEffect(() => {
    console.log("Form data update:", formData);
  }, [formData]);

  const validateForm = () => {
    const { email, permissionLevel } =
      formData;
    const errors = {
      email: "",
      team: "",
      firstName: "",
      lastName: "",
      entity: "",
      permissionLevel: "",
    };
    let isValid = true;
    // Perform validation on form fields here
    if (email.trim() === "") {
      errors.email = "Email is required";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        errors.email = "Invalid email format";
      }
    }

    // if (team.trim() === "") {
    //   errors.team = "Team is required";
    // }

    if (!permissionLevel) {
      errors.permissionLevel = "Permission level is required";
    }

    setFormErrors(errors);

    // Return true if there are no errors
    Object.values(errors).forEach((error) => {
      if (error !== "") {
        isValid = false;
      }
    });

    return isValid;
  };

  const submitForm = () => {
    setSubmissionLoading(true);
    async function call() {
      if (state.modelOpen) {
        const data = await createUserInvite(
          state.refreshToken,
          state.organizationId,
          formData.email,
          formData.permissionLevel,
          formData.team
        );
        console.log("inviteCreation:", data);
        const sendInvite = await sendUserInvite(state.refreshToken, state.organizationId, data.id)
        console.log("sendInvite data:", sendInvite)
        setSubmissionLoading(false);
        if (data) {
          handleModelOpen();
          notify();
        } else {
          errorNotify()
          return;
        }
      }
      if (state.editUserModelOpen) {
        const data = await updateOrgUser(
          state.refreshToken,
          state.editUserDetails.organization,
          state.editUserDetails.id,
          formData.email,
          formData.permissionLevel,
          formData.team
        );
        console.log("state:", state);

        console.log("EditedUser:", data);
        setSubmissionLoading(false);
        if (data) {
          state.setEditUserModelOpen(!state.editUserModelOpen);
          setIsLoading(true);
          const call = await fetchOrganizationUsers(
            state.refreshToken,
            state.organizationId
          );
          state.setUsers(call);
          setIsLoading(false);
          editSuccesNotify()
        } else {
          console.log("err while trying to update user");
          editErrorNotify()
          return;
        }
      }
    }

    call();
    console.log("helllo");
    // Handle form submission here
  };
  const [value, setValue] = React.useState<string>("");

  const handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      team: e.target.value,
    }));
    console.log(e.target.value, "target value");
    console.log(value);
  };

  useEffect(() => {
    if (state.editUserDetails) {
      console.log("edit user details", state.editUserDetails);
    }
  }, [state.editUserDetails]);

  return (
    <>
      {!state.users || !state.invitedUsers ? (
        <div className="flex grow justify-center items-center">
          <Spinner size="lg" color="secondary" />
        </div>
      ) : (
        <>
          {state.users && state.invitedUsers ? (
            <div className="h-full w-full flex flex-col items-center justify-center">
              <div className="flex  w-full h-full flex-col gap-6 relative">
                <div className="absolute z-[10] right-0 bottom-0"></div>
                <div className="flex justify-between items-center">
                  <Button
                    onPress={handleModelOpen}
                    className="flex sm:w-[226px] h-[48px] px-4 justify-center items-center gap-3 rounded-lg bg-[#3A21C4] text-inter text-base font-medium leading-6 text-white"
                  >
                    Add Member
                  </Button>

                  <Modal
                    backdrop="blur"
                    isOpen={state.modelOpen}
                    onOpenChange={onOpenChange}
                    onClose={handleModelOpen}
                    // closeButton={<Button onPress={handleModelOpen}>close</Button>}
                    placement="top-center"
                    size="2xl"
                    className="min-h-[30%]"
                  >
                    <ModalContent>
                      {(_) => (
                        <>
                          <ModalHeader className="flex p-4 justify-between items-center bg-purple-100 text-base leading-6 font-bold font-inter text-black self-stretch">
                            Employee Invitation
                          </ModalHeader>

                          <ModalBody className="pt-8 px-4">
                            <form
                              action="submit"
                              className="flex flex-col gap-4 "
                            >
                              <div className="flex align-self-stretch gap-6">
                                <Input
                                  autoFocus
                                  isRequired
                                  label="Email"
                                  placeholder="dillon@teamxylo.com"
                                  variant="bordered"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  isInvalid={!!formErrors.email}
                                  errorMessage={formErrors.email}
                                />

                                <Select
                                  label="Team"
                                  variant="bordered"
                                  isRequired
                                  placeholder="Select a Team ex: Sales"
                                  className="max-w-xs"
                                  onChange={handleSelectionChange}
                                  defaultSelectedKeys={[formData.team]}
                                >
                                  {state.organizationTeams.map(
                                    (animal: any) => (
                                      <SelectItem
                                        key={animal.id}
                                        value={animal.id}
                                      >
                                        {animal.name}
                                      </SelectItem>
                                    )
                                  )}
                                </Select>
                              </div>

                              <div className="flex align-self-stretch gap-6">
                                {/* <Input
                              
                              label="First Name"
                              isRequired
                              placeholder="Insert your First Name"
                              variant="bordered"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                              isInvalid={!!formErrors.firstName} 
                              errorMessage={formErrors.firstName}
                            />
                            <Input
                              label="Last Name (Optional)"
                              placeholder="Insert your Last Name"
                              variant="bordered"
                              isRequired
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleChange}
                              isInvalid={!!formErrors.lastName} 
                              errorMessage={formErrors.lastName}
                            /> */}
                                <Input
                                  label="Entity (Company Name)"
                                  placeholder="Eg: Google"
                                  variant="bordered"
                                  value={state.organization.name}
                                  isDisabled
                                  name="entity"
                                  onChange={handleChange}
                                  isInvalid={!!formErrors.entity}
                                  errorMessage={formErrors.entity}
                                />
                              </div>

                              <div className="pt-8">
                                <RadioGroup
                                  isRequired
                                  label="Permission level"
                                  orientation="horizontal"
                                  name="permissionLevel"
                                  value={formData.permissionLevel.toString()}
                                  onChange={handleChange}
                                  isInvalid={!!formErrors.permissionLevel}
                                  errorMessage={formErrors.permissionLevel}
                                  classNames={{
                                    wrapper: "flex gap-4",
                                  }}
                                >
                                  <Radio value="1">Admin</Radio>
                                  <Radio value="2">Employee</Radio>
                                  {/* <Radio value="no-access">No Access</Radio> */}
                                </RadioGroup>
                              </div>
                            </form>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg border-2 border-gray-300 text-base leading-6  font-inter text-black"
                              variant="flat"
                              onPress={handleModelOpen}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg bg-gray-100 text-base leading-6  font-inter text-gray-700"
                              onClick={handleSubmit}
                              isDisabled={!canSubmit}
                              isLoading={submissionLoading}
                            >
                              Send Invitation
                            </Button>
                          </ModalFooter>
                        </>
                      )}
                    </ModalContent>
                    <Toaster position="top-right">
                      {(t) => (
                        <ToastBar toast={t} style={{ background: "#F31260" }}>
                          {({ icon, message }) => (
                            <div>
                              {icon}
                              {message}
                              {t.type !== "loading" && (
                                <button
                                  onClick={() => toast.dismiss(t.id)}
                                  className="absolute top-0 right-0 p-4"
                                >
                                  <XCircle className="text-white" />
                                </button>
                              )}
                            </div>
                          )}
                        </ToastBar>
                      )}
                    </Toaster>
                  </Modal>
                  <Link to="/archived">
                    <p className="text-[#3A21C4] text-sm font-medium font-inter cursor-pointer leading-tight flex items-center gap-3">
                      See Archived <CaretRight size={20} />{" "}
                    </p>
                  </Link>
                </div>
                <div
                  className="w-full h-full bg-white rounded-lg pt-3 px-4 flex flex-col gap-2"
                  style={{
                    boxShadow:
                      "0px 0px 1px 0px rgba(0, 0, 0, 0.30), 0px 2px 10px 0px rgba(0, 0, 0, 0.06), 0px 0px 5px 0px rgba(0, 0, 0, 0.02)",
                  }}
                >
                  {/* <Input placeholder="Search"></Input> */}

                  {/* <div id="toast-success" className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        <span className="sr-only">Check icon</span>
                    </div>
                    <div className="ms-3 text-sm font-normal">Item moved successfully.</div>
                    <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </button>
                </div> */}

                  {state.users && state.invitedUsers ? (
                    <UserTable />
                  ) : (
                    <div className="flex grow justify-center items-center">
                      <Spinner size="lg" color="secondary" />
                    </div>
                  )}
                </div>
              </div>
              <Toaster position="top-right">
                {(t) => (
                  <ToastBar toast={t} style={{ background: "#EDF7ED" }}>
                    {({ icon, message }) => (
                      <div>
                        {icon}
                        {message}
                        {t.type !== "loading" && (
                          <button
                            onClick={() => toast.dismiss(t.id)}
                            className="absolute top-0 right-0 p-4"
                          >
                            <XCircle />
                          </button>
                        )}
                      </div>
                    )}
                  </ToastBar>
                )}
              </Toaster>
              ;
              {/* <Toaster   toastOptions={{
    className: '',
    style: {
      display: 'flex',
      width: '320px',
      padding: '6px 16px',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      borderRadius: '4px',
      background: '#EDF7ED',
      boxShadow: '0px 10px 15px -3px rgba(23, 201, 100, 0.40), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)'
    },
  }}>
  {(t) => (
    <ToastBar toast={t} style={{display: "flex", justifyContent: 'flex-start'}}>
      {({ icon, message }) => (
        <>
          {icon}
          {message}
          {t.type !== 'loading' && (
            <button onClick={() => toast.dismiss(t.id)}>X</button>
          )}
        </>
      )}
    </ToastBar>
  )}
</Toaster>; */}
              {/* <Toaster
  
  toastOptions={{
    className: '',
    style: {
      display: 'flex',
      width: '320px',
      padding: '6px 16px',
      alignItems: 'flex-start',
      borderRadius: '4px',
      background: '#EDF7ED',
      boxShadow: '0px 10px 15px -3px rgba(23, 201, 100, 0.40), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)'
    },
  }}
  // containerStyle={{
  //   top: 100,
  //   left: 1000,
  //   bottom: 100,
  //   right: 100,
  // }}
/> */}
            </div>
          ) : (
            <div className="h-full flex w-full items-center justify-center">
              <div className="flex flex-col justify-center items-center gap-6 self-stretch">
                <OrgSvg />
                <div className="flex flex-col gap-4">
                  <p className="text-center text-inter text-xl font-semibold leading-7 text-[#52525B] self-stretch">
                    Here you can add Employees or <br></br> Admin to start use
                    Harmony
                  </p>
                  <p className="text-center text-inter text-sm font-normal leading-5 self-stretch text-[#52525B]">
                    You have no Employees or Admin added yet
                  </p>
                </div>

                <Button
                  onPress={handleModelOpen}
                  className="flex w-[226px] h-[48px] px-4 justify-center items-center gap-3 rounded-lg bg-[#3A21C4] text-inter text-base font-medium leading-6 text-white"
                >
                  Add Member
                </Button>
                <Modal
                  backdrop="blur"
                  isOpen={state.modelOpen}
                  onOpenChange={onOpenChange}
                  onClose={handleModelOpen}
                  // closeButton={<Button onPress={handleModelOpen}>close</Button>}
                  placement="top-center"
                  size="2xl"
                  className="min-h-[30%]"
                >
                  <ModalContent>
                    {(_) => (
                      <>
                        <ModalHeader className="flex p-4 justify-between items-center bg-purple-100 text-base leading-6 font-bold font-inter text-black self-stretch">
                          Employee Invitation
                        </ModalHeader>

                        <ModalBody className="pt-8 px-4">
                          <form
                            action="submit"
                            className="flex flex-col gap-4 "
                          >
                            <div className="flex align-self-stretch gap-6">
                              <Input
                                autoFocus
                                isRequired
                                label="Email"
                                placeholder="dillon@teamxylo.com"
                                variant="bordered"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                isInvalid={!!formErrors.email}
                                errorMessage={formErrors.email}
                              />
                              <Input
                                label="Team"
                                isRequired
                                placeholder="Eg: Sales"
                                variant="bordered"
                                name="team"
                                value={formData.team}
                                onChange={handleChange}
                                isInvalid={!!formErrors.team}
                                errorMessage={formErrors.team}
                              />
                            </div>

                            <div className="flex align-self-stretch gap-6">
                              <Input
                                label="First Name"
                                isRequired
                                placeholder="Insert your First Name"
                                variant="bordered"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                isInvalid={!!formErrors.firstName}
                                errorMessage={formErrors.firstName}
                              />
                              <Input
                                label="Last Name (Optional)"
                                placeholder="Insert your Last Name"
                                variant="bordered"
                                isRequired
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                isInvalid={!!formErrors.lastName}
                                errorMessage={formErrors.lastName}
                              />
                              <Input
                                label="Entity (Company Name)"
                                placeholder="Eg: Google"
                                variant="bordered"
                                name="entity"
                                value={state.organization.name}
                                isDisabled
                                onChange={handleChange}
                                isInvalid={!!formErrors.entity}
                                errorMessage={formErrors.entity}
                              />
                            </div>

                            <div className="pt-8">
                              <RadioGroup
                                isRequired
                                label="Permission level"
                                orientation="horizontal"
                                name="permissionLevel"
                                value={
                                  formData.permissionLevel.toString() === "1"
                                    ? "admin"
                                    : "employee"
                                }
                                onChange={handleChange}
                                isInvalid={!!formErrors.permissionLevel}
                                errorMessage={formErrors.permissionLevel}
                                classNames={{
                                  wrapper: "flex gap-4",
                                }}
                              >
                                <Radio
                                  value="admin"
                                  defaultChecked={
                                    permissionLevel.toString() === "1"
                                  }
                                >
                                  Admin
                                </Radio>
                                <Radio
                                  value="employee"
                                  defaultChecked={
                                    permissionLevel.toString() === "2"
                                  }
                                >
                                  Employee
                                </Radio>
                                {/* <Radio value="no-access">No Access</Radio> */}
                              </RadioGroup>
                            </div>
                          </form>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg border-2 border-gray-300 text-base leading-6  font-inter text-black"
                            variant="flat"
                            onPress={handleModelOpen}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg bg-gray-100 text-base leading-6  font-inter text-gray-700"
                            onClick={handleSubmit}
                            isDisabled={!canSubmit}
                            isLoading={submissionLoading}
                          >
                            Send Invitation
                          </Button>

                          {/* <Toaster
  

  toastOptions={{
    className: '',
    style: {
      display: 'flex',
      width: '320px',
      padding: '6px 16px',
      alignItems: 'flex-start',
      borderRadius: '4px',
      background: '#EDF7ED',
      boxShadow: '0px 10px 15px -3px rgba(23, 201, 100, 0.40), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)'
    },
  }}
  containerStyle={{
    top: 20,
    left: 20,
    bottom: 20,
    right: 0,
  }}
/> */}
                        </ModalFooter>
                      </>
                    )}
                  </ModalContent>
                </Modal>
              </div>
            </div>
          )}

          {state.editUserModelOpen ? (
            <Modal
              backdrop="blur"
              isOpen={state.editUserModelOpen}
              onClose={handleEditModelOpen}
              onOpenChange={onOpenChange}
              // onClose={state.setEditUserModelOpen(false)}
              // closeButton={<Button onPress={handleModelOpen}>close</Button>}
              placement="top-center"
              size="2xl"
              className="min-h-[30%]"
            >
              <ModalContent>
                {(_) => (
                  <>
                    <ModalHeader className="flex p-4 justify-between items-center bg-purple-100 text-base leading-6 font-bold font-inter text-black self-stretch">
                      Edit Employee Information
                    </ModalHeader>

                    <ModalBody className="pt-8 px-4">
                      {isLoading ? (
                        <div className="flex grow justify-center items-center">
                          <Spinner size="lg" color="secondary" />
                        </div>
                      ) : (
                        <form action="submit" className="flex flex-col gap-4 ">
                          <div className="flex align-self-stretch gap-6">
                            <Input
                              autoFocus
                              label="Email"
                              placeholder="dillon@teamxylo.com"
                              variant="bordered"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              isInvalid={!!formErrors.email}
                              errorMessage={formErrors.email}
                              isDisabled
                            />
                            {/* <Input
                              label="Team"
                              isRequired
                              placeholder="Eg: Sales"
                              variant="bordered"
                              name="team"
                              value={formData.team}
                              onChange={handleChange}
                              isInvalid={!!formErrors.team} 
                              errorMessage={formErrors.team}
                            /> */}

                            <Select
                              label="Team"
                              variant="bordered"
                              placeholder="Select a Team ex: Sales"
                              className="max-w-xs"
                              onChange={handleSelectionChange}
                              defaultSelectedKeys={[formData.team.toString()]}
                            >
                              {state.organizationTeams.map((animal: any) => (
                                <SelectItem key={animal.id} value={animal.id}>
                                  {animal.name}
                                </SelectItem>
                              ))}
                            </Select>
                          </div>

                          <div className="flex align-self-stretch gap-6">
                            <Input
                              label="First Name"
                              placeholder="Insert your First Name"
                              variant="bordered"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                              isInvalid={!!formErrors.firstName}
                              errorMessage={formErrors.firstName}
                              isDisabled
                            />
                            <Input
                              label="Last Name (Optional)"
                              placeholder="Insert your Last Name"
                              variant="bordered"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleChange}
                              isInvalid={!!formErrors.lastName}
                              errorMessage={formErrors.lastName}
                              isDisabled
                            />
                            <Input
                              isDisabled
                              label="Entity (Company Name)"
                              placeholder="Eg: Google"
                              variant="bordered"
                              name="entity"
                              value={formData.entity}
                              onChange={handleChange}
                              isInvalid={!!formErrors.entity}
                              errorMessage={formErrors.entity}
                            />
                          </div>

                          <div className="pt-8 ">
                            <RadioGroup
                              isRequired
                              label="Permission level"
                              orientation="horizontal"
                              name="permissionLevel"
                              // value={formData.permissionLevel.toString() === "1" ? "admin" : "employee"}
                              onChange={handleChange}
                              classNames={{
                                wrapper: "flex gap-4",
                              }}
                              defaultValue={formData.permissionLevel.toString()}
                            >
                              <Radio value="1">Admin</Radio>
                              <Radio value="2">Employee</Radio>
                            </RadioGroup>
                          </div>
                        </form>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg border-2 border-gray-300 text-base leading-6  font-inter text-black"
                        variant="flat"
                        onPress={handleEditModelOpen}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="flex h-[40px] px-4 justify-center items-center gap-3 rounded-lg bg-gray-100 text-base leading-6  font-inter text-gray-700"
                        onClick={handleSubmit}
                        isDisabled={!canSubmit}
                        isLoading={submissionLoading}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
          ) : null}
        </>
      )}
    </>
  );
};

export default Organization;
