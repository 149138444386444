// StateManager.tsx
import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { fetchUserId, fetchUserDetails, fetchOrganization, fetchOrganizationUsers, fetchOrganizationTeams, fetchRefreshToken, fetchInviteList } from './api';

interface StateManagerContextType {
  state: {
    userId: null | string | void,
    organizationId: any,
    refreshToken: any,
    users: any,
    setUsers: any,
    editUserModelOpen: any,
    setEditUserModelOpen: any,
    editUserDetails: any,
    setEditUserDetails: any,
    editUserEmail: any,
    setEditUserEmail: any,
    organization: any,
    setOrganization: any,
    modelOpen: any,
    setModelOpen: any,
    userDetails: any,
    deleteModelOpen: any,
    setDeleteModelOpen: any,
    isSameClickedUser: any,
    setIsSameClickedUser: any,
    organizationTeams: any,
    archivedUsers: any,
    setArchivedUsers: any,
    invitedUsers: any,
    setInvitedUsers: any;
  }
  // Define any functions or actions here
}

// Create the context
const StateManagerContext = createContext<StateManagerContextType | undefined>(undefined);

// Define your provider component
export const StateManagerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Define your state and any state manipulation functions here

  const [userId, setUserId] = useState<null | string | void>(null)
  const [userDetails, setUserDetails] = useState<null | string | void>(null)
  const [organizationId, setOrganizationId] = useState<null | string | void>(null)
  const [organization, setOrganization] = useState(null)
  const [organizationTeams, setOrganizationTeams] = useState(null)
  const [refreshToken, setRefreshToken] = useState<null | string | void>(null)
  const [users, setUsers] = useState<any>(null)
  const [editUserModelOpen, setEditUserModelOpen] = useState(null)
  const [editUserDetails, setEditUserDetails] = useState(null)
  const [editUserEmail, setEditUserEmail] = useState(null)
  const [modelOpen, setModelOpen] = useState(false)
  const [deleteModelOpen, setDeleteModelOpen] = useState(false)
  const [isSameClickedUser, setIsSameClickedUser] = useState(false)
  const [archivedUsers, setArchivedUsers] = useState(null)
  const [invitedUsers, setInvitedUsers] = useState(null)


  React.useEffect(() => {
    let refreshToken: any = Cookies.get("access_token");
    const actualRefreshToken: any = Cookies.get("refresh_token")

    const call = async () => {

      setRefreshToken(refreshToken)
      if (refreshToken) {
        console.log("Refresh Token:", refreshToken);
        // Do something with the refresh token, such as storing it in state or performing further actions

        const userId = await fetchUserId(refreshToken);
        console.log('userId:', userId)
        if (!userId) {
          const newToken = await fetchRefreshToken(actualRefreshToken)
          console.log(newToken, "NewToken")
          setRefreshToken(newToken)
          refreshToken = newToken
          call()
        }
        const userDetails = await fetchUserDetails(refreshToken, userId.user_id)
        setUserId(userId.user_id);
        console.log('userDetails:', userDetails)
        setUserDetails(userDetails)
        console.log("Org Number:", userDetails.organizations[0].organization)
        setOrganizationId(userDetails.organizations[0].organization)
        const organization = await fetchOrganization(refreshToken, userDetails.organizations[0].organization)
        console.log("Org Name:", organization)
        setOrganization(organization)
        const organizationTeams = await fetchOrganizationTeams(refreshToken, userDetails.organizations[0].organization)
        console.log("Org Teams:", organizationTeams)
        setOrganizationTeams(organizationTeams)

        const organizationUsers = await fetchOrganizationUsers(refreshToken, userDetails.organizations[0].organization)
        console.log("Org Users:", organizationUsers);

        const activeUsers = organizationUsers.filter((user: any) => user.active === true);
        console.log("Active Users:", activeUsers);

        const archivedUsers = organizationUsers.filter((user: any) => user.active === false);
        console.log("Archived Users:", archivedUsers);

        setUsers(activeUsers);
        setArchivedUsers(archivedUsers);
        const invitedUsers = await fetchInviteList(refreshToken, userDetails.organizations[0].organization)
        setInvitedUsers(invitedUsers)


      } else {
        console.log("Refresh Token not found");

      }
    }
    call()

  }, []);



  const state = {
    userId,
    organizationId,
    refreshToken,
    users,
    editUserModelOpen,
    setEditUserModelOpen,
    editUserDetails,
    setEditUserDetails,
    editUserEmail,
    setEditUserEmail,
    organization,
    setOrganization,
    modelOpen,
    setModelOpen,
    userDetails,
    deleteModelOpen,
    setDeleteModelOpen,
    isSameClickedUser,
    setIsSameClickedUser,
    organizationTeams,
    setUsers,
    archivedUsers,
    setArchivedUsers,
    invitedUsers,
    setInvitedUsers
  };



  return (
    <StateManagerContext.Provider value={{ state }}>
      {children}
    </StateManagerContext.Provider>
  );
};

// Custom hook to consume the StateManager context
export const useStateManager = () => {
  const context = useContext(StateManagerContext);
  if (!context) {
    throw new Error('useStateManager must be used within a StateManagerProvider');
  }
  return context;
};
