import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Tooltip,
} from "@nextui-org/react";
import { useStateManager } from "../handlers/statemanager";

import React from "react";
import { Question, ChatText, Gear, X } from "@phosphor-icons/react";

interface HeaderProps { }

const Header: React.FC<HeaderProps> = () => {
  const { state } = useStateManager();
  return (
    <Navbar className="bg-[#110A44]  " maxWidth="full">
      <NavbarBrand className="flex gap-2 items-center">
        <div className="">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5511 2.05475C9.90825 3.34624 10.2824 4.74382 10.5511 5.28287C10.5511 4.3861 10.9069 4.20601 11.2639 3.75824C11.6209 3.31047 12.8686 2.77142 13.5826 3.31047C14.2954 3.84828 15.1873 4.47491 15.9001 3.48933C16.6141 2.50252 15.9001 0.710213 14.385 0.171165C12.8686 -0.365416 11.3535 0.441306 10.5511 2.05475Z"
              fill="white"
            />
            <path
              d="M13.3161 8.50992C13.9748 8.99611 14.7388 9.31825 15.5453 9.44987C18.9338 9.97288 20.5385 7.34425 19.8245 5.82085C19.1117 4.29623 17.6849 4.20618 16.8825 4.29623C16.0802 4.38627 15.1883 5.10295 14.1185 5.37185C13.0487 5.64076 12.6021 5.82085 12.3347 6.53753C12.0672 7.25544 12.8009 8.1226 13.3161 8.51116V8.50992Z"
              fill="white"
            />
            <path
              d="M5.55854 5.28341C6.34248 5.99268 6.48847 6.73896 7.25279 7.52348C9.26725 9.59579 11.7111 9.85483 13.8519 10.8416C15.9915 11.8285 16.616 12.5439 16.616 13.6208C16.616 14.6964 16.0811 14.9653 16.616 15.4131C17.1509 15.8621 18.2918 15.7387 19.2009 14.9653C20.2057 14.1105 20.4768 11.583 18.666 10.8416C17.2392 10.257 16.7043 10.7516 14.7426 10.1237C12.7809 9.49711 11.8902 8.68915 10.5517 7.2558C9.21449 5.82122 9.48195 2.7732 9.12617 1.78638C8.76916 0.799567 7.32272 -0.466024 5.55854 0.172939C4.17589 0.673748 3.51462 1.9159 3.59805 3.13092C3.68025 4.34717 4.66785 4.47545 5.55854 5.28341Z"
              fill="white"
            />
            <path
              d="M9.43402 17.9442C10.0757 16.6539 9.70147 15.2551 9.43402 14.7173C9.43402 15.6141 9.07701 15.7929 8.72001 16.2407C8.36422 16.6897 7.1153 17.2275 6.40251 16.6897C5.68849 16.1519 4.79658 15.524 4.08379 16.5096C3.37101 17.4964 4.0838 19.2899 5.59893 19.8278C7.1153 20.3656 8.63167 19.5588 9.43402 17.9442Z"
              fill="white"
            />
            <path
              d="M6.67166 11.4889C6.0129 11.0032 5.24893 10.6815 4.4425 10.5502C1.05399 10.0272 -0.550704 12.6546 0.162086 14.1792C0.876102 15.7026 2.30291 15.7927 3.10526 15.7026C3.90761 15.6138 4.79952 14.8959 5.86932 14.627C6.93912 14.3581 7.38445 14.1792 7.6519 13.4613C7.91934 12.7446 7.18693 11.8762 6.67166 11.4889Z"
              fill="white"
            />
            <path
              d="M14.4288 14.7172C13.6448 14.008 13.4988 13.2617 12.7345 12.4759C10.7201 10.4049 8.2762 10.1446 6.1366 9.15778C3.99578 8.1722 3.37256 7.45552 3.37256 6.37989C3.37256 5.30427 3.90746 5.03536 3.37256 4.58636C2.83643 4.13859 1.69547 4.26194 0.786392 5.03536C-0.218385 5.89019 -0.489509 8.41767 1.3213 9.15901C2.7481 9.74247 3.283 9.24782 5.24471 9.87569C7.20641 10.5035 8.09832 11.3103 9.43557 12.7449C10.7728 14.1794 10.5054 17.2275 10.8624 18.213C11.2181 19.1999 12.6646 20.4654 14.4288 19.8277C15.8114 19.3257 16.4739 18.0835 16.3905 16.8685C16.307 15.6535 15.3207 15.524 14.4288 14.7172Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="flex items-center justify-center">
          {state.userDetails && state.userDetails.email && (
            <p
              className="text-white opacity-0 transition-opacity duration-1000 delay-1000 "
              style={{ opacity: 1 }}
            >
              {state.userDetails.email}
            </p>
          )}
        </div>
        <div className="text-white flex  items-center">
          <Tooltip
            content="FAQ"
            placement="top"
            className="bg-black text-white p-1 rounded-[8px] px-3"
          >
            <div className="p-1 hover:bg-white hover:text-purple-500 rounded">
              <a href="https://myubq.com/faq" target="_blank" rel="noreferrer">
                <Question size={18} className="cursor-pointer" />
              </a>
            </div>
          </Tooltip>
          <Tooltip
            content="Feedback"
            placement="top"
            className="bg-black text-white p-1 rounded-[8px] px-3"
          >
            <div className="p-1 hover:bg-white hover:text-purple-500 rounded">
              {/* eslint-disable-next-line */}
              <a href="#">
                <ChatText size={18} className="cursor-pointer" />
              </a>
            </div>
          </Tooltip>
          <Tooltip
            content="Settings"
            placement="top"
            className="bg-black text-white p-1 rounded-[8px] px-3"
          >
            <div className="p-1 hover:bg-white hover:text-purple-500 rounded">
              {/* eslint-disable-next-line */}
              <a href="#">
                <Gear size={18} className="cursor-pointer" />
              </a>
            </div>
          </Tooltip>
        </div>
      </NavbarBrand>

      <NavbarContent justify="end">
        <NavbarItem className="flex items-center">
          <X size={24} className="text-white cursor-pointer" />
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};

export default Header;
