


const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

export const fetchRefreshToken = async (refreshToken: string | undefined) => {

  try {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'User-Agent': 'insomnia/8.6.1' },
      body: JSON.stringify({ refresh: refreshToken })
    };

    fetch('https://app-dev.teamxylo.com/api/token/refresh/', options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }
};



export const fetchUserId = async (refreshToken: string) => {
  try {
    const response = await fetch(`${BASE_URL}/users/id`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("Data:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false
    // Handle fetch error, e.g., retry, show error message, etc.
  }
};



// curl -X PATCH \
// -H "Authorization: Bearer <refreshToken>" \
// -H "Content-Type: application/json" \
// -d '{
//   "team": "<team>"
// }' \
// "https://app-dev.teamxylo.com/api/organizations/<orgId>/invitelist/<userId>"


export const fetchUserDetails = async (refreshToken: string, userId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/users/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("Data:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }

}
export const fetchOrganization = async (refreshToken: string, orgId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("Data:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }

}

export const fetchOrganizationTeams = async (refreshToken: string, orgId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}/teams/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("Org Teams:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }

}

export const fetchOrganizationUsers = async (refreshToken: string, orgId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}/users/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("Data:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }

}

export const createUserInvite = async (refreshToken: any, orgId: any, email: string, role: any, _: any) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}/invitelist/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        role: role,
        // team:
      })
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("Data:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }

}

export const sendUserInvite = async (refreshToken: any, orgId: any, inviteId: any) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}/send-invitations/${inviteId}/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        "Content-Type": "application/json"
      }

    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("sendUserInvite Data:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }

}

//https://app-dev.teamxylo.com/api/organizations/7/users/29

export const deleteOrgUser = async (refreshToken: any, orgId: any, userId: any) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}/users/${userId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        // email: email,
        active: false,

      })
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("User Deleted:", data);
    return { data: data, response: response }
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }


}

export const unArchiveUser = async (refreshToken: any, orgId: any, userId: any) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}/users/${userId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        // email: email,
        active: true,

      })
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("User Deleted:", data);
    return { data: data, response: response }
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }


}

export const updateOrgUser = async (refreshToken: any, orgId: any, userId: any, _: any, role: any, team: any) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}/users/${userId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        // email: email,
        role: role,
        team: team
      })
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("User Deleted:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }

}


export const fetchInviteList = async (refreshToken: string, orgId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${orgId}/invitelist/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Proceed with handling the response
    const data = await response.json();
    console.log("Org Invitelist:", data);
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    // Handle fetch error, e.g., retry, show error message, etc.
  }

}
